<template>
  <div class="pagination-default">
    <div class="pagination-default__nav lg-flex-hidden left" :class=" {'disabled' :prevDisabled}" @click="slidePrev">
      <BaseIcon name="west" />
    </div>
    <div class="pagination-default__bullets">
      <div
        class="pagination-default__bullets--item"
        :class="[{'pagination-default__bullets--active':firstActive}]"
        @click="slidePrev"
      />
      <div
        v-if="showCenter"
        class="pagination-default__bullets--item"
        :class="{'pagination-default__bullets--active':centerActive}"
        @click="slideCenter"
      />
      <div
        class="pagination-default__bullets--item"
        :class="[{'pagination-default__bullets--active':lastActive}]"
        @click="slideNext"
      />
    </div>
    <div class="pagination-default__nav lg-flex-hidden  right" :class="{'disabled' :nextDisabled}" @click="slideNext">
      <BaseIcon name="east" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderPaginationDefault',
  props: {
    showCenter: {
      type: Boolean,
      default: false
    },
    firstActive: {
      type: Boolean,
      default: false
    },
    centerActive: {
      type: Boolean,
      default: false
    },
    lastActive: {
      type: Boolean,
      default: false
    },
    prevDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    slideNext () {
      this.$emit('slideNext')
    },
    slideCenter () {
      this.$emit('slideCenter')
    },
    slidePrev () {
      this.$emit('slidePrev')
    }
  }
}
</script>

<style lang="scss" >
.pagination-default {
  @apply flex items-center mt-10 justify-center;
  &__bullets {
    @apply flex justify-center mx-[20%];
    &--item {
      @apply mx-1 bg-white border border-primary-200 inline-block rounded-full cursor-pointer
      w-3 h-3;
    }
    &--active {
      @apply  border-primary bg-primary;
    }
  }
  &__nav {
    @apply justify-center items-center cursor-pointer rounded-full w-10 h-10;
    &.disabled {
      @apply opacity-50 cursor-default;
    }
    &.left {
      background: linear-gradient(60.52deg, #85aff8 -84.87%, rgba(204, 228, 255, 0.02) 91.32%);
    }
    &.right {
      background: linear-gradient(290.52deg, #85aff8 -84.87%, rgba(204, 228, 255, 0.02) 91.32%);
    }
  }
}
</style>
