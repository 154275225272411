<template>
  <section class="top-tutors">
    <div class="top-tutors__header">
      <BaseTitleSection :title="title" class="top-tutors__title" />
    </div>
    <TopTutorsGroups
      v-if="showCourseGroup"
      :courses="courses"
      :active-course="selectedCourse"
      @clicked="courseClicked"
    />
    <TopTutorsSlider v-if="!loading" :teachers="teachers" />
    <TopTutorsSkeleton v-else />
    <TopTutorsAction />
  </section>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import TopTutorsGroups from '@/components/pages/home/top-tutors/top-tutors-groups.vue'
import TopTutorsSlider from '@/components/pages/home/top-tutors/top-tutors-slider.vue'
import TopTutorsAction from '@/components/pages/home/top-tutors/top-tutors-action.vue'
import TopTutorsSkeleton from '@/components/pages/home/top-tutors/top-tutors-skeleton.vue'

export default {
  name: 'TopTutors',
  components: { TopTutorsSkeleton, TopTutorsAction, TopTutorsSlider, TopTutorsGroups },
  data () {
    return {
      selectedCourse: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('global', ['getBestTeachers']),
    title () {
      return this.$t('common.titles.top_tutors')
    },
    subtitle () {
      return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. '
    },
    courses () {
      return this.getBestTeachers?.courses
    },
    teachers () {
      return this.getBestTeachers?.teachers
    },
    showCourseGroup () {
      return this.getBestTeachers?.show_courses
    }
  },
  mounted () {
    this.selectedCourse = this.courses.find((val) => {
      return val.is_selected
    })
  },
  methods: {
    ...mapActions('global', ['fetchBestTeachers']),
    async courseClicked (course) {
      this.loading = true
      await this.fetchBestTeachers(course.id)
      this.selectedCourse = course
      this.loading = false
    }
  }
}
</script>
<style scoped lang="scss">
.top-tutors {
  @apply container text-center space-y-8;
  &__subtitle {
    @apply mt-4 text__body-2-md--regular text-body-1;
  }
}
</style>
