<script>
import CommonFindBestTutorsBtn from '@/components/common/find-best-tutors/find-best-tutors-btn.vue'

export default {
  name: 'TopTutorsAction',
  components: { CommonFindBestTutorsBtn }
}
</script>

<template>
  <div class="top-tutors-action">
    <CommonFindBestTutorsBtn name="top-tutors" />
    <BaseButton
      outline
      light
      icon-left-custom="teacher"
      classes="lg:w-[245px] w-full"
      size="md-lg"
      tag="a"
      :label="$t('actions.become_tutor')"
      :to="$links.absolute($nuxt, 'start_teaching')"
    />
  </div>
</template>

<style scoped lang="scss">
.top-tutors-action {
  @apply flex lg:space-x-6 justify-center flex-col lg:flex-row space-y-3 lg:space-y-0;
}
</style>
