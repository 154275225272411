<template>
  <client-only>
    <SliderBody
      :items="items"
      :options="options"
      :pagination="pagination"
    >
      <template #item="{data}">
        <slot
          name="item"
          :data="data"
        />
      </template>
    </SliderBody>
  </client-only>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import SliderBody from './slider-body.vue'

export default defineComponent({
  name: 'BaseSlider',
  components: {
    SliderBody
  },
  props: {
    pagination: {
      type: [String, Boolean],
      default: 'SliderPaginationDefault'
    },
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  }
})
</script>
