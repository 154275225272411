<template>
  <!-- Slider main container -->
  <div ref="swiper" class="swiper">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div
        v-for="(item, index) in items"
        :key="index"
        class="swiper-slide"
      >
        <slot
          name="item"
          :data="item"
          :index="index"
        />
      </div>
    </div>
    <div>
      <component
        :is="pagination"
        v-if="pagination"
        :show-center="items.length >= 3"
        :first-active="firstActive"
        :last-active="lastActive"
        :center-active="centerActive"
        :prev-disabled="prevDisabled"
        :next-disabled="nextDisabled"
        @slidePrev="slidePrev"
        @slideCenter="slideCenter"
        @slideNext="slideNext"
      />
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'
import SliderPaginationDefault from './slider-pagination-default'
import SliderPaginationArrow from './slider-pagination-arrow.vue'

export default {
  name: 'SliderBody',
  components: {
    SliderPaginationDefault,
    SliderPaginationArrow
  },
  props: {
    pagination: {
      type: [String, Boolean],
      default: 'SliderPaginationDefault'
    },
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      firstActive: true,
      lastActive: false
    }
  },
  computed: {
    centerActive () {
      return !this.lastActive && !this.firstActive
    },
    prevDisabled () {
      return !this.options.loop && this.firstActive
    },
    nextDisabled () {
      return !this.options.loop && this.lastActive
    },
    swiperComponentOption () {
      const swiperComponentOption = {
        watchOverflow: true,
        spaceBetween: 24,
        slidesPerView: 2,
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
      return { ...swiperComponentOption, ...this.options }
    }
  },
  async mounted () {
    await this.$nextTick()
    const swiper = new Swiper(this.$refs.swiper, {
      ...this.swiperComponentOption
    })
    swiper.on('slideChange', function () {
      this.slideChanged()
    }.bind(this))
  },
  methods: {
    slideChanged () {
      this.activeIndex = this.$refs.swiper.swiper.activeIndex
      this.firstActive = this.$refs.swiper.swiper.isBeginning
      this.lastActive = this.$refs.swiper.swiper.isEnd
    },
    slidePrev () {
      this.$refs.swiper.swiper.slidePrev()
    },
    slideCenter () {
      let centerSlideIndex = this.items.length / 2
      centerSlideIndex = Math.ceil(centerSlideIndex)
      this.$refs.swiper.swiper.slideTo(centerSlideIndex)
    },
    slideNext () {
      this.$refs.swiper.swiper.slideNext()
    }
  }
}
</script>
<style scoped>
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
