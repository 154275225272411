<script>
import BaseSlider from '@app/design-system/base/slider/slider.vue'
import TopTutorsCard from '@/components/pages/home/top-tutors/top-tutors-card.vue'

export default {
  name: 'TopTutorsSlider',
  components: { TopTutorsCard, BaseSlider },
  props: {
    teachers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sliderOption: {
        spaceBetween: 16,
        slidesPerView: 3,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 24
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1.34,
            spaceBetween: 16
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  }
}
</script>

<template>
  <div class="top-tutors-slider">
    <BaseSlider
      :items="teachers"
      :options="sliderOption"
      pagination="SliderPaginationArrow"
    >
      <template #item="{ data }">
        <TopTutorsCard
          :teacher="data"
        />
      </template>
    </BaseSlider>
  </div>
</template>

<style scoped lang="scss">
.top-tutors-slider {
}
</style>
